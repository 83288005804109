import {
  projectSymbol,
  documentSymbol,
  nodeSymbol,
  nodeChildrenSymbol,
  designerSymbol,
  skeletonSymbol,
  editorSymbol,
  settingFieldSymbol,
  settingTopEntrySymbol,
  designerCabinSymbol,
  propSymbol,
  simulatorHostSymbol,
  skeletonItemSymbol,
  editorCabinSymbol,
  skeletonCabinSymbol,
  simulatorRenderSymbol,
} from '@redhare/lowcode-shell';

export default {
  projectSymbol,
  documentSymbol,
  nodeSymbol,
  nodeChildrenSymbol,
  skeletonSymbol,
  editorSymbol,
  designerSymbol,
  settingPropEntrySymbol: settingFieldSymbol,
  settingTopEntrySymbol,
  designerCabinSymbol,
  editorCabinSymbol,
  skeletonCabinSymbol,
  propSymbol,
  simulatorHostSymbol,
  skeletonItemSymbol,
  simulatorRenderSymbol,
};
